import pick from "@/utils/pick";
import { getHead, mergeHead, getGoogleAdScript } from "./common";

// Meta
function getMeta(data) {
  return [
    {
      name: "keywords",
      content: data.keyword,
    },
    {
      name: "description",
      content: data.description,
    },
  ];
}

// Open graph protocol
function getVideoOGMeta(data) {
  return [
    {
      property: "og:type",
      content: "video",
    },
    {
      property: "og:video",
      content: data.videoUrl,
    },
    {
      property: "og:video:secure_url",
      content: data.videoUrl,
    },
    {
      property: "og:video:type",
      content: "video/mp4",
    },
  ];
}

// Google Search Structure Data
function getVideoStructureDataHeadMeta(data) {
  return [
    {
      type: "application/ld+json",
      json: {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        ...pick({
          name: "title",
          description: "description",
          thumbnailUrl: "thumb",
          uploadDate: "uploadDate",
          duration: "duration",
          contentUrl: "videoUrl",
        })(data),
        interactionStatistic: {
          "@type": "InteractionCounter",
          interactionType: {
            "@type": "http://schema.org/WatchAction",
          },
          userInteractionCount: data.playCount || 0,
        },
        publisher: {
          "@type": "Organization",
          name: data.siteName,
          logo: {
            "@type": "ImageObject",
            url: "https://www.tnaot.com/img/logo-256.png",
          },
        },
      },
    },
  ];
}

export default function getVideoHead(video) {
  const data = pick({
    siteName: "siteName",
    pageLocale: "pageLocale",
    locale: 'locale',
    pageUrl: "pageUrl",
    canonicalPageUrl: "canonicalPageUrl",
    mobileUrl: "mobileUrl",
    title: "title",
    thumb: "thumbs.0",
    keyword: "keyword",
    description: "description",
    uploadDate: "release_timestamp,date-iso",
    duration: "duration,duration-iso-8601",
    videoUrl: "video_url",
    playCount: "play_count",
  })(video);

  return mergeHead(
    { meta: getMeta(data) },
    getHead(data),
    { script: [...getVideoStructureDataHeadMeta(data), ...getGoogleAdScript()] },
    { meta: getVideoOGMeta(data) }
  );
}
