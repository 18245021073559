import pick from "@/utils/pick";
import { getHead, mergeHead, getGoogleAdScript } from "./common";

// Meta
function getMeta(data) {
  return [
    {
      name: "keywords",
      content: data.keyword,
    },
    {
      name: "description",
      content: data.description,
    },
  ];
}

// Open graph protocol
function getArticleOGMeta(data) {
  return [
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:article:tag",
      content: data.keyword,
    },
    {
      property: "og:article:published_time",
      content: data.datePublished,
    },
    {
      property: 'article:author',
      content: 'https://facebook.com/Tnaot.kh/'
    }
  ];
}

// Google Search Structure Data
function getArticleStructureDataMeta(data) {
  return [
    {
      type: "application/ld+json",
      json: {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        ...pick({
          headline: "title",
          image: "thumb",
          datePublished: "datePublished",
        })(data),
        author: {
          "@type": "Organization",
          name: data.siteName,
          logo: {
            "@type": "ImageObject",
            url: "https://www.tnaot.com/img/logo-256.png",
          },
        },
        publisher: {
          "@type": "Organization",
          name: data.siteName,
          logo: {
            "@type": "ImageObject",
            url: "https://www.tnaot.com/img/logo-256.png",
          },
        },
      },
    },
  ];
}

export default function getArticleHead(article) {
  const data = pick({
    siteName: "siteName",
    pageLocale: "pageLocale",
    locale: 'locale',
    pageUrl: "pageUrl",
    canonicalPageUrl: "canonicalPageUrl",
    mobileUrl: "mobileUrl",
    title: "title",
    thumb: "thumbs.0",
    keyword: "keyword",
    description: "description",
    datePublished: "release_timestamp,date-iso",
  })(article);

  return mergeHead(
    { meta: getMeta(data) },
    getHead(data),
    { script: [...getArticleStructureDataMeta(data), ...getGoogleAdScript()] },
    { meta: getArticleOGMeta(data) }
  );
}
