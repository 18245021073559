function getServerUserAgentLanguage(req) {
  return req.headers['accept-language'] || '';
}

function getClientUserAgentLanguage() {
  if (typeof window === 'undefined') return '';
  return window.navigator.language;
}

function getUserAgentLanguage(...rest) {
  if (process.client) {
    return getClientUserAgentLanguage(...rest);
  }
  return getServerUserAgentLanguage(...rest);
}

export { getUserAgentLanguage };
