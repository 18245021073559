
import PlaceholderImage from '../assets/images/placeholder.png';

export default {
  props: {
    src: [Number, String],
    defaultImage: {
      type: String,
      default: PlaceholderImage
    },
    defaultErrorImage: {
      type: String,
      default: PlaceholderImage
    },
    maybeSvg: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      errorImage: ''
    };
  },

  computed: {
    url() {
      return this.errorImage || this.src || this.defaultImage;
    },
    isSvg() {
      return /\/[^.]+\.svg/i.test(this.src);
    }
  },

  methods: {
    onImageError() {
      this.errorImage = this.defaultErrorImage;
    }
  },

  render() {
    if (this.maybeSvg && this.isSvg) {
      return (
        <svg {...{ attrs: { ...this.$attrs } }}>
          <use xlink:href={this.src} href={this.src}></use>
        </svg>
      );
    } else {
      return (
        <img
          {...{ attrs: { ...this.$attrs } }}
          v-lazy={this.url}
          vOn:error={this.onImageError}
        />
      );
    }
  }
};
