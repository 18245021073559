
import { LANGUAGES } from '@/constants/index';

export default {
  data() {
    return {
      LANGUAGES,
      showDropdown: false
    };
  },

  computed: {
    language() {
      return this.$store.state.app.language;
    },
    languageObj() {
      const { language } = this;
      const item = LANGUAGES.find(t => t.value === language);
      return item || {};
    }
  },

  beforeDestroy() {
    this.removeListener();
  },

  methods: {
    selectLanguage(item) {
      if (item.value === this.language) return;
      this.$store.dispatch('app/updateLanguage', item.value);
      this.showDropdown = false;
      this.removeListener();
      setTimeout(() => {
        this.$router.push({
          path: this.localePath('/', this.$i18n.locale)
        });
      }, 100);
    },

    showDropdownModal() {
      document.addEventListener('click', this.registerListener);
      this.showDropdown = true;
    },

    registerListener(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
        this.removeListener();
      }
    },

    removeListener() {
      document.removeEventListener('click', this.registerListener);
    }
  }
};
