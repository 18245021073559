export const state = () => ({
  language: 'km',
  searchContent: ''
});

export const mutations = {
  updateLanguage(state, payload) {
    state.language = payload;
  },
  updateSearchContent(state, payload) {
    state.searchContent = payload;
  }
};

export const actions = {
  updateLanguage({ commit }, locale) {
    this.$cookies.set('locale', locale, {
      path: '/',
      maxAge: 60 * 60 * 24 * 31
    });
    this.$i18n.setLocale(locale);
    commit('updateLanguage', locale);
  }
};




