export const BackToTop = () => import('../..\\components\\BackToTop.vue' /* webpackChunkName: "components/back-to-top" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../..\\components\\Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BottomFixed = () => import('../..\\components\\BottomFixed.vue' /* webpackChunkName: "components/bottom-fixed" */).then(c => wrapFunctional(c.default || c))
export const Channel = () => import('../..\\components\\Channel.vue' /* webpackChunkName: "components/channel" */).then(c => wrapFunctional(c.default || c))
export const ChannelList = () => import('../..\\components\\ChannelList.vue' /* webpackChunkName: "components/channel-list" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../..\\components\\Comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../..\\components\\Error.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const FriendlyScrollList = () => import('../..\\components\\FriendlyScrollList.vue' /* webpackChunkName: "components/friendly-scroll-list" */).then(c => wrapFunctional(c.default || c))
export const HeaderFixed = () => import('../..\\components\\HeaderFixed.vue' /* webpackChunkName: "components/header-fixed" */).then(c => wrapFunctional(c.default || c))
export const HeaderNav = () => import('../..\\components\\HeaderNav.vue' /* webpackChunkName: "components/header-nav" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../..\\components\\HomeBanner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))
export const HotAndRecommended = () => import('../..\\components\\HotAndRecommended.vue' /* webpackChunkName: "components/hot-and-recommended" */).then(c => wrapFunctional(c.default || c))
export const HotTopic = () => import('../..\\components\\HotTopic.vue' /* webpackChunkName: "components/hot-topic" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../..\\components\\Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const LanguageSelect = () => import('../..\\components\\LanguageSelect.vue' /* webpackChunkName: "components/language-select" */).then(c => wrapFunctional(c.default || c))
export const LatestNews = () => import('../..\\components\\LatestNews.vue' /* webpackChunkName: "components/latest-news" */).then(c => wrapFunctional(c.default || c))
export const LinkTopic = () => import('../..\\components\\LinkTopic.vue' /* webpackChunkName: "components/link-topic" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../..\\components\\Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const More = () => import('../..\\components\\More.vue' /* webpackChunkName: "components/more" */).then(c => wrapFunctional(c.default || c))
export const MxLink = () => import('../..\\components\\MxLink.vue' /* webpackChunkName: "components/mx-link" */).then(c => wrapFunctional(c.default || c))
export const NewsInfoBig = () => import('../..\\components\\NewsInfoBig.vue' /* webpackChunkName: "components/news-info-big" */).then(c => wrapFunctional(c.default || c))
export const NewsInfoSmall = () => import('../..\\components\\NewsInfoSmall.vue' /* webpackChunkName: "components/news-info-small" */).then(c => wrapFunctional(c.default || c))
export const NewsInfoTitle = () => import('../..\\components\\NewsInfoTitle.vue' /* webpackChunkName: "components/news-info-title" */).then(c => wrapFunctional(c.default || c))
export const NewsItem = () => import('../..\\components\\NewsItem.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c))
export const NewsTitle = () => import('../..\\components\\NewsTitle.vue' /* webpackChunkName: "components/news-title" */).then(c => wrapFunctional(c.default || c))
export const NoContent = () => import('../..\\components\\NoContent.vue' /* webpackChunkName: "components/no-content" */).then(c => wrapFunctional(c.default || c))
export const NoMore = () => import('../..\\components\\NoMore.vue' /* webpackChunkName: "components/no-more" */).then(c => wrapFunctional(c.default || c))
export const NoSearchContent = () => import('../..\\components\\NoSearchContent.vue' /* webpackChunkName: "components/no-search-content" */).then(c => wrapFunctional(c.default || c))
export const ScrollToFixed = () => import('../..\\components\\ScrollToFixed.vue' /* webpackChunkName: "components/scroll-to-fixed" */).then(c => wrapFunctional(c.default || c))
export const ShareBox = () => import('../..\\components\\ShareBox.vue' /* webpackChunkName: "components/share-box" */).then(c => wrapFunctional(c.default || c))
export const Statement = () => import('../..\\components\\Statement.vue' /* webpackChunkName: "components/statement" */).then(c => wrapFunctional(c.default || c))
export const Swiper = () => import('../..\\components\\Swiper.vue' /* webpackChunkName: "components/swiper" */).then(c => wrapFunctional(c.default || c))
export const SwiperItem = () => import('../..\\components\\SwiperItem.vue' /* webpackChunkName: "components/swiper-item" */).then(c => wrapFunctional(c.default || c))
export const TAvatar = () => import('../..\\components\\TAvatar.vue' /* webpackChunkName: "components/t-avatar" */).then(c => wrapFunctional(c.default || c))
export const TFooter = () => import('../..\\components\\TFooter.vue' /* webpackChunkName: "components/t-footer" */).then(c => wrapFunctional(c.default || c))
export const TImage = () => import('../..\\components\\TImage.vue' /* webpackChunkName: "components/t-image" */).then(c => wrapFunctional(c.default || c))
export const TopicAndNews = () => import('../..\\components\\TopicAndNews.vue' /* webpackChunkName: "components/topic-and-news" */).then(c => wrapFunctional(c.default || c))
export const TopicSummary = () => import('../..\\components\\TopicSummary.vue' /* webpackChunkName: "components/topic-summary" */).then(c => wrapFunctional(c.default || c))
export const TopNews = () => import('../..\\components\\TopNews.vue' /* webpackChunkName: "components/top-news" */).then(c => wrapFunctional(c.default || c))
export const TTabs = () => import('../..\\components\\TTabs.vue' /* webpackChunkName: "components/t-tabs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
