import { format, isThisYear } from 'date-fns';

// 第一个参数均为ctx 主要用于解决国际化问题

const parseTime = (ctx, timestamp, fmt = 'yyyy-MM-dd HH:mm:ss', hideYear) => {
  if (!timestamp) return '';

  try {
    if (hideYear && isThisYear(new Date(timestamp))) {
      return format(timestamp, 'MM-dd HH:mm');
    }

    return format(timestamp, fmt);
  } catch (e) {
    return '-'
  }
};

const tagFilter = (ctx, tags) => {
  if (!tags) return ''
  return tags.reduce((p, t) => {
    p += `#${t}#  `
    return p
  }, '')
}

// 时长转换 1小时内显示刚刚  24小时及以内显示 N小时前  48小时及以内显示昨天 未跨年显示日期  跨年显示年月日
const duration = (ctx, timeStamp) => {
  if (!timeStamp) return '';
  const now = new Date();
  const nowTime = Date.parse(now);
  const oneHour = 1000 * 60 * 60;
  const oneDay = 1000 * 60 * 60 * 24;
  const twoDay = 1000 * 60 * 60 * 48;
  const lastYear = Date.parse((now.getFullYear() + "-01-01"));
  const difference = nowTime - timeStamp;

  if (timeStamp < lastYear) {
    return parseTime(ctx, timeStamp, 'yyyy-MM-dd')
  } else if (difference <= oneHour) {
    return ctx.app.i18n.t('just_now')
  } else if (difference > oneHour && difference <= oneDay) {
    return Math.floor(difference / 3600000) + ctx.app.i18n.t('hours_ago')
  } else if (difference > oneDay && difference <= twoDay) {
    return ctx.app.i18n.t('yesterday')
  } else {
    return parseTime(ctx, timeStamp, 'MM-dd')
  }
}

export { parseTime, duration, tagFilter };
