
export default {
  data() {
    return {
      show: false
    };
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    this.removeListeners();
  },

  methods: {
    init() {
      if (process.server) return;
      this.check();
      window.addEventListener('scroll', this.check);
    },
    removeListeners() {
      if (process.server) return;
      window.removeEventListener('scroll', this.check);
    },
    check() {
      const { scrollY } = window;
      const height = window.innerHeight;
      this.show = scrollY > height * 1.5;
    },
    backTop() {
      window.scrollTo(0, 0);
    }
  }
};
