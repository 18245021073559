import localecc40c576 from '../..\\lang\\km-KH.js'
import localeca376520 from '../..\\lang\\zh-Hans.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","file":"km-KH.js"},{"code":"zh","file":"zh-Hans.js"},{"code":"km","file":"km-KH.js"}],
  defaultLocale: "",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "D:\\ruanjian\\MX\\tnaot_www_pc\\lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","file":"km-KH.js"},{"code":"zh","file":"zh-Hans.js"},{"code":"km","file":"km-KH.js"}],
  localeCodes: ["en","zh","km"],
  additionalMessages: [],
}

export const localeMessages = {
  'km-KH.js': () => Promise.resolve(localecc40c576),
  'zh-Hans.js': () => Promise.resolve(localeca376520),
}
