import Vue from 'vue';
import VueLazyLoad from 'vue-lazyload';
const defaultImg = require('../assets/images/placeholder.png');

Vue.use(VueLazyLoad, {
  preLoad: 1.33,
  error: defaultImg,
  loading: defaultImg,
  attempt: 1
});
